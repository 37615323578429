
<script setup lang="ts">
    import { computed } from "vue";
    import { colorUtils } from 'o365-utils'; 

    import colors from "./colors.ts";

    const props = defineProps({
        variant: { type: String, default: "contained" },
        size: { type: String, default: "md" },
        color: { type: String, default: "muted", required: false },
        cssColor: { type: String },
        fontWeight: { type: String, default: "fw-normal"},
        uppercase: {},
        first: {},
        last: {},
        modelValue: {},
        onClick: null,
        onClose: null,
        text: String,
    });
    const emit = defineEmits(["update:modelValue", "click", "close"]);

    const pillColor = computed(() => {
        if (props.cssColor != null) {

            return props.cssColor
        } else if (props.color != null) {

            return colors[props.color].background
        } else {
            return ""
        }
    });

    // const pillTextColor = "black"

    const style = computed(() => {
        const style = {};
        if (props.modelValue != null) {
            /* new version with model value */
            if (props.modelValue) {
                return {
                    color: colorUtils.textColor(pillColor.value), // colors[props.color].color
                    backgroundColor: pillColor.value,
                    // borderWidth: "1px",
                    // borderStyle: "solid",
                    borderColor: "none",
                }
            } else {
                return {
                    color: "black",
                    // borderWidth: "1px",
                    // borderStyle: "solid",
                    borderColor: pillColor.value,
                    background: "",
                }
            }
        }
        if (props.variant === "contained") {
            style.color = colorUtils.textColor(pillColor.value);
            style.backgroundColor = pillColor.value;
            // style.borderWidth = "1px";
            // style.borderStyle = "solid";
            style.borderColor = colors[props.color].border || "none";
        } else if (props.variant === "outlined") {
            //style.color = colors[props.color].background;
            style.color = "black";
            style.borderWidth = "1px";
            style.borderStyle = "solid";
            style.borderColor = pillColor.value;
            style.background = "";
        }
        if (props.first) {
            style.borderTopRightRadius = "0";
            style.borderBottomRightRadius = "0";
        } else if (props.last) {
            style.borderTopLeftRadius = "0";
            style.borderBottomLeftRadius = "0";
            style.borderLeft = "0";
        } else if (props.first != null) {
            style.borderRadius = "0";
            style.borderLeft = "0";
        }
        return style;
    });

    function onClick(e) {
        emit("click", e);
        emit("update:modelValue", !props.modelValue);
    }

</script>

<template>
    <div
        class="afm-pill"
        :class="{ 'uppercase tracking-4': uppercase != null, ['afm-pill-' + props.size]: true, [props.fontWeight]: true }"
        :style="style"
        @click="onClick"
    >
        <div class="flex-grow-1 d-flex align-items-center gap-1 text-truncate">
            <template v-if="modelValue">
                <div>
                    <i class="bi bi-check" style="font-size: 1.25em;" />
                </div>
            </template>
            <div class="text-truncate" style="padding: 0.25rem 0rem;">
                <slot>
                    {{ text }}
                </slot>
            </div>
        </div>
        <template v-if="props.onClose">
            <a
                class="flex-shrink-0 d-flex justify-content-center align-items-center"
                style="height: 1rem; aspect-ratio: 1; border-radius: 999px; text-decoration: none;"
                @click.stop="emit('close', $event)"
            >
                <i class="far fa-times" style="position: relative; top: 1px; font-size: 0.875em;" />
            </a>
        </template>
    </div>
</template>

<style scoped>
    .afm-pill {
        display: flex;
        align-items: center;
        gap: 0.25rem;

        /* background-color: rgb(90%, 90%, 90%); */
        /* padding: 0rem 0.75rem; */
        padding: 0rem 0.75rem;
        border-radius: 999px;
        box-sizing: border-box;
        transition: all 100ms ease-in-out;
    }

    .afm-pill-sm {
        font-size: 0.8125em;
        height: 1.875rem;
    }

    .afm-pill-md {
        font-size: 0.875em;
        height: 2.25rem;
    }

    .afm-pill-lg {
        font-size: 1em;
        height: 2.25rem;
    }
</style>
